import React from 'react';
import { useState, useContext, useEffect } from 'react';
import { PaperClipIcon, DocumentIcon } from '@heroicons/react/20/solid'
import { InputValidator } from '../helper/InputValidator'
import { sendPostRequest, API_CONFIG } from '../helper/HttpHelper'
import { classNames } from './Helper'
import { AuthContext } from '../store/auth-context';
import { SuccessAlert, ErrorAlert } from './Alert';
import { useNavigate, useLocation } from 'react-router-dom';
import { uploadFileS3, deleteFileS3 } from '../helper/FileUpload';
import { PreLoginHeader, TopGradient, BottomGradient, PreLoginLogo, LoadingOverlay } from "../component/Common";

const CreateForm = ({ formJSON, isPreLoginForm = false, stack = 'vertical', dataSetter, ...otherProps }) => {
  console.log('Other Props', otherProps)
  console.log('Workflow is - ', otherProps.workflow)
  const [formData, setFormData] = useState({});
  const [fileUploaded, setFileUploaded] = useState(false)
    
  const [errors, setErrors] = useState({});
  const [submissionError, setSubmissionError] = useState([]);
  const [httpLoading, setHttpLoading] = useState(false);
  const [success, showSuccess] = useState(false);
  const [selectData, setSelectData] = useState({});
  const {token, loading, isAuthenticated, logout, loanAction, setLoanAction} = useContext(AuthContext);
  const authContext = useContext(AuthContext);
  const navigate = useNavigate()
  

  const handleLogout = () => {
    authContext.logout()
    navigate('/login')
  }
  const handleInputChange = (field, value) => {
    try{
      setFormData((prevData) => ({ ...prevData, [field]: value }));  
    }catch(e){
      console.error("createForm - handleInputChange " + e)
    }
  }
  const handleSubmit = async () => {
    console.log("createForm - handleSubmit - Form is submitted")
    //return 
    setErrors({})
    let isValid = true;
    console.log(JSON.stringify(formData))
    //return
    for(var i = 0; i < formJSON.body.length; i++){
      let item = formJSON.body[i]
      if(item.required){
        if(item.validator === undefined) item.validator = []
          item.validator.push('isNotEmpty')
        if(item.error === undefined) item.error = []
          item.error.push('This field is required')
      }
      
      if(!validateField(item.field, item.validator, item.validator_fields, item.error) ){
        isValid = false;
        break;
      }
    }
    console.log("Errors array is  " + JSON.stringify(errors))
    if(!isValid){
      return  
    }
    let params = { 'method': formJSON.submit.method, 'data' : { ...formData ?? {}, ...formJSON.submit.data ?? {} } }

    if(token && token.eid) //user is logged in
      params['meta'] = { 'token': token } 
    
    let res = await sendPostRequest({'url': formJSON.submit.endpoint, 'payload': params, 'setLoading':setHttpLoading, 'handleLogout': handleLogout })
    if(res.status === 'error' && ['TOKEN_INVALID', 'TOKEN_EXPIRED', 'TOKEN_NOT_SUPPLIED'].includes(res.code)){
      logout();
      navigate('/login')
    }
    if(res.status === 'error'){
      console.error("In CreateForm - handleSubmit - Error is " + res.message)
      setSubmissionError([res.message])
    }
    if(res.status === 'success'){
      if(res.token){ //For login flow
        authContext.authenticate(res.token);
      }
      if(dataSetter){
        dataSetter(res.data)
      }
        //Update loanAction and change the current state to completed and set the next state to current
      if (otherProps.workflow && loanAction && loanAction.length > 0) {
        const updatedLoanAction = [...loanAction]
        for( var i = 0; i < updatedLoanAction.length; i++){
          if(updatedLoanAction[i].status === 'current'){
            updatedLoanAction[i].status = 'complete';
            //updatedLoanAction[i] = {...loanAction[i], status: 'complete'}
            //loanAction[i].status = 'complete';
            if(i + 1 < updatedLoanAction.length){
              updatedLoanAction[i + 1].status = 'current';
            }
            break;
          }
        }
        setFormData({})    
        setLoanAction(updatedLoanAction);
      }else{  
        formJSON.submit.navigate && navigate(formJSON.submit.navigate)
        setFormData({})  
        showSuccess(true)
      }
      
    }  
    console.log(res )
  }
  useEffect(() => {
    const fetchData = async () => {
      try{
          console.log('In Form Builder - useEffect - fetchData')
          for(var i = 0; i < formJSON.body.length; i++){
            let field = formJSON.body[i];
            if(field.type === "FTDynamicSelect" ){
              console.log("In useEfect - Setting up FTDynamicSelect")
              let res = await sendPostRequest({'url': field.fetch.endpoint, 'payload': { 'method': field.fetch.method, 'meta': { 'token': token } }, 'setLoading':setHttpLoading, 'handleLogout': handleLogout })
              let data = [];
              res && res.data && res.data.forEach((item) => {
                console.log("Form -  Item is " + JSON.stringify(item))
                if(!item['ATTR1'])
                  data.push({k:item.SK, l:item.SK }) 
                else{
                  let obj = typeof item['ATTR1'] === "string" ? JSON.parse(item['ATTR1']):item['ATTR1'] 
                  console.log("FormBuilderScreen- Pushing Object - " + JSON.stringify(obj))
                  data.push({k:item.SK, l:obj[field.fetch.key]}) 
                }  
                //data.push({k:item.SK, l:item[field.fetch.key] })   
              })
              const sortedArray = [...data].sort((a, b) => a.l.localeCompare(b.l));
              console.log("FormBuilderScreen - after sorting - " + JSON.stringify(sortedArray))
              setSelectData((prevData) => ({ ...prevData, [field.field]: sortedArray }));
            }
          }
      }catch(e){
          console.error("createForm - useEffect - fetchData " + e)
      }
        
    }
    (!dataSetter) && fetchData()
  }, [])
  useEffect(() => {
    const fetchData = async () => {
      try{
        let res = await sendPostRequest({'url': formJSON.submit.getpoint, 'payload': { 'method': formJSON.submit.method, 'meta': { 'token': token, 'eid':token.eid}  }, 'setLoading':setHttpLoading, 'handleLogout': handleLogout }) 
        if(res.data){
          const obj = typeof res.data.ATTR1 === "string" ? JSON.parse(res.data.ATTR1):res.data.ATTR1 
          console.log("createForm - useEffect - fetchData - Response is " + JSON.stringify(obj))
          setFormData(obj);
        }        
        //console.log("createForm - useEffect - fetchData - Response is " + JSON.stringify(res))
      }catch(e){
          console.error("createForm - useEffect - fetchData " + e)
      }
    }
    if(!isPreLoginForm){ //If its not prelogin form, then check if user is authenticated
      if(!loading && !isAuthenticated && (!token || Object.keys(token).length === 0)){
        console.error("In CreateForm - Not authenticated..login again ...")
        navigate("/login")
      }else{
        fetchData()
      }
    }else{ //If its prelogin form, then check if user is authenticated
      if(isAuthenticated && token && Object.keys(token).length > 0){
        console.log("In CreateForm - Already authenticated..redirecting to home ...")
        navigate("/home")
      }
      console.log("In CreateForm - useEffect - isPreLoginForm is")
    }
  }, [token])  
  const validateField = (fieldName, validators, validator_fields, errors) => {
    try{
      console.log("In createForm - validateField" + JSON.stringify([fieldName, validators, validator_fields, errors]))
      let isValid = true
      //fieldValue = fieldValue ?? '';
      validators = validators ?? [];
      validator_fields = validator_fields ?? [];
      errors = errors ?? [];
      for(var i = 0; i < validators.length; i++){
        let params = []
        params.push(formData?.[fieldName] || '')
        if(validator_fields[i] !== undefined){
          for(var j = 0; j < validator_fields[i].length; j++){
            params.push(formData[validator_fields[i][j]])
          }
        }
        let r = InputValidator[validators[i]](...params) 
        if(!r){ //Returns false when validation fails. eg isEmail - Will return false if its not email
          isValid = false;
          setErrors((prevErrors) => ({
            ...prevErrors,
            [fieldName]: errors[i] 
          }))
          break;
        }     
      }            
      return isValid;              
    }catch(e){
      console.error("In createForm - Error validateField - " + e )
    }
  }
 
  function  renderElement(props, index){
      switch(props.type){
          case 'FTTitle':
              return FTTitle(props, index)
          case 'FTTextInput':
              return FTTextInput(props, index)
          case 'FTTextInput1':
            return FTTextInput1(props, index)    
          case 'FTTextAreaComponent':
            return FTTextAreaComponent(props, index)    
          case 'FTFileComponent':
            return FTFileComponent(props, index)
          case 'FTButton':
              return FTButton(props, index)
          case 'FTSelect':
              return FTSelect(props, index)
          case 'FTRadio':
              return FTRadio(props, index) 
          case 'FTCheckBox':
            return FTCheckBox(props, index)     
          case 'FTLink':
            return FTLink(props, index)      
          case 'FTDynamicSelect':
            return FTDynamicSelect(props, index)   
          case 'FTMultiTextInput':
            return FTMultiTextInput(props, index)   
          case 'FTDate':
            return FTDate(props, index)     
          default:
              return <></>
      }
  }

  function FTCheckBox(props, index){
    function handleChange(e) {
      // Extract the current list of checked items, defaulting to an empty array if undefined
      let checkedItems = formData[props.field] ?? [];
      if (e.target.checked) {
        // If the item is checked and not already in the list, add it
        if (!checkedItems.includes(e.target.value)) {
          checkedItems.push(e.target.value);
        }
      } else {
        // If the item is unchecked, remove it from the list
        checkedItems = checkedItems.filter(item => item !== e.target.value);
      }   
      // Update the form data with the new list of checked items
      setFormData(prevData => ({ ...prevData, [props.field]: checkedItems }));
    }
    return (
      <fieldset key={index} className='my-4'>
        <label htmlFor={props.field} className="block text-sm font-medium leading-6 text-gray-900">
          {props.label}{(props.required)?<span className='text-red-500 ml-1'>*</span>:''}
        </label>
        <div className="space-y-5 my-4">
          {props.options.map((o, i) => (
            <div className="relative flex items-start">
                <div className="flex h-6 items-center">
                  <input
                    id={props.field}
                    name={props.field}
                    value={o.k}
                    type="checkbox"
                    aria-describedby="comments-description"
                    onChange={handleChange}
                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                  />
                </div>
                <div className="ml-3 text-sm leading-6">
                  <label htmlFor="comments" className="font-medium text-gray-500">
                    {o.l}
                  </label>
                </div>
            </div>
          ))}
          
        </div>
        {( errors[props.field] !== "" )?<p className="mt-2 text-sm text-red-600">{errors[props.field]}</p>:''}
      </fieldset>
    )
  }
  function FTRadio(props, index){
    function handleChange(e){
      console.log("In FTRadio - handleChange - " + e.target.value)
      let params = {}
      params[props.field] = e.target.value
      params[props.field + '_label' ] = props.options.filter((o) => o.k === e.target.value)[0].l
      setFormData((prevData) => ({ ...prevData, ...params }));
    }
    return (
      <fieldset key={index} className='my-4'>
        <label htmlFor={props.field} className="block text-sm font-medium leading-6 text-gray-900">
          {props.label}{(props.required)?<span className='text-red-500 ml-1'>*</span>:''}
        </label>
        <div className="mt-4 space-y-3">
          {props.options.map((o, i) => (
            <div key={o.k} className="flex items-center">
              <input
                defaultChecked={o.k === 'kgs'}
                id={props.field}
                name={props.field}
                value={o.k}
                type="radio"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                onChange={handleChange} 
              />
              <label htmlFor={o.k} className="ml-3 block text-sm font-medium leading-6 text-gray-500">
                {o.l}
              </label>
            </div>
          ))}
        </div>
        {( errors[props.field] !== "" )?<p className="mt-2 text-sm text-red-600">{errors[props.field]}</p>:''}
      </fieldset>
      
    )
  }
  
  function FTLink(props, index){
    
    return <p className={classNames("mt-10 text-center text-sm/6 text-gray-500", (props.display && !success)?props.display:"block")} key={index}> 
              {props.mesg1}{' '}
              <a href={props.url} className="font-semibold text-indigo-600 hover:text-indigo-500">
                {props.mesg2}
              </a>
            </p>
  }
  function FTButton(props, index){
    return <div className="pt-10 flex items-center justify-end gap-x-6"  key={index}>
                <button
                  type="submit"
                  //Implment the save function
                  onClick={() => handleSubmit()}
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm/6 font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  {props.title}
                </button>
            </div>
  }
  function FTFileComponent(props, index){
    
    const changeHandler = async (event) => {
      console.log(event.target.files[0]);
      const res = await uploadFileS3(event.target.files[0], setHttpLoading)
      console.log(res)
      if(res.status === 'success'){
        setFileUploaded(true)
        setFormData((prevData) => ({
          ...prevData,
          [props.field]: prevData && prevData[props.field] && Array.isArray(prevData[props.field])
              ? [...prevData[props.field], {'url': res.url, 'fname': res.fname, 'size': res.size, 'hash': res.hash}]  
            : [{'url': res.url, 'fname': res.fname, 'size': res.size, 'hash': res.hash}]
        }));
      }
      console.log("In FTFileComponent - changeHandler - Response is " + JSON.stringify(res))
    }
    return <div className="col-span-ful py-4" key={index}>
                <label htmlFor="cover-photo" className="block text-sm font-medium leading-6 text-gray-900">
                  {props.label}
                </label>
                <div class="mt-2 sm:flex sm:items-start sm:justify-between">
                    <div class="max-w-xl text-sm text-gray-500">
                      <p id="renew-subscription-description">
                        <DocumentIcon aria-hidden="true" className="mx-auto h-12 w-12 text-gray-300" />
                        <label htmlFor={props.field} className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500">
                            <span>{props.placeholder}</span>
                            <input id={props.field} name={props.field} type="file" className="sr-only" onChange={changeHandler} />
                        </label>      
                        <span className="text-xs leading-5 text-gray-600 mx-2">PDF up to 3MB</span>
                      </p>
                    </div>
                </div>
                { <FTFileAttachment {...props} fileUploaded={fileUploaded}></FTFileAttachment> }
            </div>
  }
  function FTFileAttachment(props){
    if(!formData || formData && (formData[props.field] === undefined || formData[props.field].length === 0)){
      return <></>
    }
  
    const deleteFile = (hash) =>{
      formData[props.field].map((item) => {
        if(item.hash === hash)
          deleteFileS3(item.name, setHttpLoading)  
      })
      let newFiles = formData[props.field].filter((item) => !item.hash || item.hash !== hash)
      setFormData((prevData) => ({
        ...prevData,
        [props.field]: newFiles
      }));
    }
    return (
      <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-sm font-medium leading-6 text-gray-900">Attachments</dt>
            <dd className="mt-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
              <ul role="list" className="divide-y divide-gray-100 rounded-md border border-gray-200">
                {formData && formData[props.field].map((item, index) => (
                <li className="flex items-center justify-between py-4 pl-4 pr-5 text-sm leading-6" key={index}>
                  <div className="flex w-0 flex-1 items-center">
                    <PaperClipIcon aria-hidden="true" className="h-5 w-5 flex-shrink-0 text-gray-400" />
                    <div className="ml-4 flex min-w-0 flex-1 gap-2">
                      <span className="truncate font-medium">{item.fname}</span>
                      <span class="flex-shrink-0 text-gray-400">
                          {item.size && !isNaN(item.size) ? `${Math.floor(Number(item.size) / 1000)} KB` : "NA"}
                      </span>
                    </div>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a href="#" onClick={(e) => {e.preventDefault(); item.hash && deleteFile(item.hash)}} className="font-medium text-indigo-600 hover:text-indigo-500">
                      Delete
                    </a>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a href={item.url} className="font-medium text-indigo-600 hover:text-indigo-500">
                      Download
                    </a>
                  </div>
                </li>
                ))}
              </ul>
            </dd>
          </div>
    )
  }
  function FTTextAreaComponent(props, index){
    return <div className="col-span-full" key={index}>
              <label htmlFor="about" className="block text-sm font-medium leading-6 text-gray-900">
                {props.label}
              </label>
              <div className="mt-2">
                <textarea
                  id={props.field}
                  name={props.field}
                  rows={3}
                  onChange={(e) => { handleInputChange(props.field, e.target.value)} }
                  value={(formData && props.field && formData[props.field])?formData[props.field]:''}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder={props.placeholder}
                />
              </div>
          </div>
  }
  function FTTitle(props, index){
      if(props.display === undefined ) { return <div key={index}>
                <h2 className="text-base font-semibold leading-7 text-gray-900">{props.title}</h2>
                <p className="mt-1 text-sm leading-6 text-gray-600">
                  {props.subtitle}
                </p>
            </div> }
  }
   //values:[{l:'UX Research', k:'ux'},{l:'Web Development', k:'wb'} ]
  const FTDynamicSelect = (props, index) => { //Dynamic Means data loaded from backend using builder in config file
    props.options = selectData[props.field]; // The select data is loaded from backend in useEffect init code. 
    console.log("Dynamic Select Values for field - " + props.field + JSON.stringify(props.options))
    return FTSelect(props, index)
  }

  function FTSelect(props, index){
    const className = ( errors[props.field] && errors[props.field] !== "" )?"mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-red-600 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6":"mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6" 
    function handleSelectChange(field, selectedValue){
      let params = {}
      params[field] = selectedValue
      const matchingOption = props.options.find(o => o.k === selectedValue);
      console.log("In FTSelect - handleSelectChange - " + JSON.stringify(matchingOption))
      params[field + '_label'] = matchingOption && matchingOption.k ? matchingOption.l : '';
      console.log("In FTSelect - handleSelectChange - " + JSON.stringify(params))
      setFormData((prevData) => ({ ...prevData, ...params }));      
    }
    return (
      <div key={index} className='py-2'>
        <label htmlFor="location" className="block text-sm font-medium leading-6 text-gray-900">
          {props.label}{(props.required)?<span className='text-red-500 ml-1'>*</span>:''}
        </label>
        <select
          id={props.field}
          name={props.field}
          onChange={(e) => handleSelectChange(props.field, e.target.value) }
          value={(formData && props.field && formData[props.field])?formData[props.field]:''}
          className={className}
        > <option value=""></option>
          {
          props?.options?.map((o, index) => (
            <option value={o.k} key={index}>{o.l}</option>
          ))
        }
        </select>
        {( errors[props.field] !== "" )?<p className="mt-2 text-sm text-red-600">{errors[props.field]}</p>:''}
      </div>
    )
  }
  function FTTextInput1(props, index){
    const className = ( errors[props.field] && errors[props.field] !== "" )? "block w-full rounded-md border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6" : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    return (
      <div key={index} className="pt-6 sm:flex">
          <dt className="font-medium text-gray-900 sm:w-64 sm:flex-none sm:pr-6">{props.label}
          {(props.required)?<span className='text-red-500 ml-1'>*</span>:''}
          </dt>
          <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
            <div className='flex flex-col'>
                  <input
                        defaultValue=""
                        id={props.field}
                        name={props.field}
                        type={props.inputType || 'text'} 
                        value={(formData[props.field] !== undefined)?(formData[props.field] + ''):''}
                        placeholder={props.placeholder}
                        onChange={(e) => { handleInputChange(props.field, e.target.value)} }
                        className={className}
                  />
                  {( errors[props.field] !== "" )?<p className="mt-2 text-sm text-red-600">{errors[props.field]}</p>:''}
              </div>
              
          </dd>
      </div>
    )
  }
  
  function FTDate(props, index){
    return (
      <div key={index} className="pt-4">
        <div className="flex items-center justify-between">
          <label htmlFor={props.field} className="block text-sm font-medium leading-6 text-gray-900">
            {props.label}{(props.required)?<span className='text-red-500 ml-1'>*</span>:''}
          </label>
        </div>
        <div className="relative mt-2 rounded-md shadow-sm">
          <input
            defaultValue=""
            id={props.field}
            name={props.field}
            type="date"
            value={(formData && formData[props.field] !== undefined)?(formData[props.field] + ''):''}
            placeholder={props.placeholder}
            onChange={(e) => { handleInputChange(props.field, e.target.value)} }
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
          <p className="mt-2 text-sm text-gray-500">
              {(props.helperText)?props.helperText:''}
          </p>
        </div>
        {( errors[props.field] !== "" )?<p id="email-error" className="mt-2 text-sm text-red-600">{errors[props.field]}</p>:''}
      </div>
    )
  }
  function FTMultiTextInput(props, index) {
    //const [financials, setFinancials] = React.useState([]);
    let financials = []
    //React.useEffect(() => {
    if (!formData || formData[props.field] === undefined) {
        const quarters = props.header_fields();
        const initialFinancials = props?.params?.length > 0 && props.params.map((param) => {
            const row = { name: param };
            quarters.forEach((quarter) => {
                row[quarter] = '';
            });
            return row;
        });
        //setFinancials(initialFinancials);
        financials = initialFinancials;
    }
    else {
      financials = formData[props.field]
    }
    //}, []);

    const handleInputChange = (field, rowIndex, quarter, value) => {
        console.log("In handleInputChange - " + JSON.stringify([field, rowIndex, quarter, value]))
        console.log(financials)
        const updatedFinancials = (formData && formData[field] !== undefined)?[...formData[field]]:financials;
        updatedFinancials[rowIndex][quarter] = value;
        setFormData((prevData) => ({ ...prevData, [field]: updatedFinancials }));
        console.log(formData)

        //setFinancials(updatedFinancials);
        //formData[props.field] = updatedFinancials;
    };

    return (
        <div className="inline-block min-w-full py-3 align-middle sm:px-6 lg:px-8">
            <h2 className="text-base font-semibold text-gray-600 py-4">Last Six Quarters Financial Info</h2>
            <table className="min-w-full divide-y divide-gray-300">
                <thead>
                    <tr className="divide-x divide-gray-200">
                        <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0"></th>
                        {financials[0] && Object.keys(financials[0]).map((quarter, index) => (
                            index !== 0 && <th key={index} scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                                {quarter}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                    {financials.map((row, rowIndex) => (
                        <tr key={row.name} className="divide-x divide-gray-200">
                            {Object.keys(row).map((key, index) => (
                                index === 0 ? (
                                    <td key={index} className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                                        {row.name}
                                    </td>
                                ) : (
                                    <td key={index} className="whitespace-nowrap p-4 text-sm text-gray-500">
                                        <div className="relative mt-2">
                                            <input
                                                name={key}
                                                type="text"
                                                value={( formData && formData[props.field] !== undefined)?(formData[props.field][rowIndex][key] + ''):''}
                                                placeholder=""
                                                onChange={(e) => handleInputChange(props.field, rowIndex, key, e.target.value)}
                                                className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                                            />
                                            <div
                                                aria-hidden="true"
                                                className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-indigo-600"
                                            />
                                        </div>
                                    </td>
                                )
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
  }
  function FTTextInput(props, index){
    const className = ( errors[props.field] && errors[props.field] !== "" )? "block w-full rounded-md border-0 py-1.5 pr-10 text-red-900 ring-1 ring-inset ring-red-300 placeholder:text-red-300 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6" : "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
    return (
            <div key={index} className="pt-4">
                <div className="flex items-center justify-between">
                  <label htmlFor={props.field} className="block text-sm font-medium leading-6 text-gray-900">
                    {props.label}{(props.required)?<span className='text-red-500 ml-1'>*</span>:''}
                  </label>
                  {( props.link && props.link.url )?<div className="text-sm">
                      <a href={props.link.url} className="font-semibold text-indigo-600 hover:text-indigo-500">
                      {props.link.text}
                      </a>
                    </div>:''}
                </div>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    id={props.field}
                    name={props.field}
                    type={props.inputType || 'text'} 
                    value={( formData && formData[props.field] !== undefined)?(formData[props.field] + ''):''}
                    placeholder={props.placeholder}
                    onChange={(e) => { handleInputChange(props.field, e.target.value)} }
                    onKeyDown={(e) => { if (e.key === 'Enter') handleSubmit(); }}
                    className={className}
                  />
                  <p className="mt-2 text-sm text-gray-500">
                      {(props.helperText)?props.helperText:''}
                  </p>
                </div>
                {( errors[props.field] !== "" )?<p id="email-error" className="mt-2 text-sm text-red-600">{errors[props.field]}</p>:''}
            </div>
      )
  }
  function signUpForm(){ 
    return (
      <div className="bg-white">
        <div className="relative isolate px-6 pt-14 lg:px-8">
          <TopGradient></TopGradient>
            <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-2 lg:px-8">
              <PreLoginLogo title={formJSON.body[0].title}></PreLoginLogo>
              <div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
                {success && <SuccessAlert message="Successfully submitted." hideSuccess={() => showSuccess(false)} ></SuccessAlert>}
                {submissionError.length > 0 && <ErrorAlert errors={submissionError} ></ErrorAlert>}
                { (httpLoading)?<LoadingOverlay></LoadingOverlay>:formJSON.body.map((props, index) => ( renderElement(props, index) ))}
              </div>
            </div>
            <BottomGradient></BottomGradient>
        </div>
      </div>
    )
  }
  function loggedInForm(){
    return (
      <>
        {success && <SuccessAlert message="Successfully updated" hideSuccess={() => showSuccess(false)} ></SuccessAlert>}
        <div className="border-b border-gray-900/10 pb-12">
          <div className={(stack === 'horizontal')?'flex space-x-4':'flex flex-col space-y-1'}>
          { (httpLoading)?<LoadingOverlay/> : formJSON?.body.length > 0 && formJSON.body.map((props, index) => ( renderElement(props, index) ))}
          </div>  
        </div>
      </>
    )
  }
  if(isPreLoginForm){
      //return (httpLoading)?<LoadingOverlay></LoadingOverlay>:signUpForm(formJSON, renderElement, submissionError)
      return signUpForm()
  }
  return loggedInForm()

}

export {CreateForm}