import { API_CONFIG, sendPostRequest } from '../../helper/HttpHelper'
import { CreateForm } from '../../component/Form'
import { camelCaseToTitle, readableDate, formatFileSize, classNames, timeAgo, isObject } from '../../component/Helper';
import { BuildList } from '../../component/List'
import React, { useState, useContext, useEffect } from 'react'
import { AuthContext } from '../../store/auth-context'  
import { useNavigate, useParams } from 'react-router-dom';
import { Modal } from '../../component/Modal'
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import { componentMapping } from '../../component/Component';
import { LoadingOverlay } from '../../component/Common';

const statuses = {
    'sanctioned': 'text-green-700 bg-green-50 ring-green-600/20',
    'applying': 'text-gray-600 bg-blue-50 ring-gray-500/10',
    'under_review': 'text-yellow-800 bg-yellow-50 ring-yellow-600/20',
  }
function PdfFileElement({name, size, index}){
    return (
      <div className="flex items-center space-x-4" key={index}>
          <span className="flex-shrink-0">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm1 18H5V4h10v16zm-9-9h6v2H6v-2zm0 4h6v2H6v-2z" fill="red"/>
          </svg>
          </span>
          <div className="flex-1 min-w-0">
              <p className="text-sm font-medium text-gray-900 truncate">
                {name}
              </p>
              <p className="text-sm text-gray-500 truncate">
                {formatFileSize(size)}
              </p>
          </div>
        </div>
    )
}
function LoanFeedComponent({item, handleClick}){
    console.log('LoanFeedComponent - ' + JSON.stringify(item))
    const feedJSON = {
        body:{
            'format':{
                  'performa_invoice_date':readableDate, 'target_date':readableDate },
                   'value_append':{ 'funding_expected': 'Lakhs', 'performa_invoice_amount': 'Rs.', 'expected_loan_tenure_label':'months'
                },
            'action':{},
            'exclude':['eid', 'supplier','id', 'expected_loan_tenure', 'activity', 'loan_action', 'status']
        }
    }

    if(!item || !isObject(item) || !item.ATTR1 || !feedJSON || !feedJSON.body ){ 
        console.log("Returning Null ")
        return null
    }

    const renderChunks = (item) => {
        console.log('renderChunks - ' + JSON.stringify(item))
        const attributes = item.ATTR1 && Object.entries(item.ATTR1).filter(([key, value]) => !feedJSON.body.exclude.includes(key));
        console.log("Attributes - " + JSON.stringify(attributes)) 
        const chunkSize = 4;
        const chunks = [];
        for (let i = 0; i < attributes.length; i += chunkSize) {
          const chunk = attributes.slice(i, i + chunkSize);
          chunks.push(
            <div className="mt-1 grid grid-cols-4 text-xs gap-x-10 leading-5 text-gray-500" key={i}>
              {chunk.map(([key, value], index) => {
                const label = camelCaseToTitle(key, true);
                return label && key.includes("_file") ? <PdfFileElement name={value[0]['fname']} size={value[0]['size']}></PdfFileElement>: (
                  <p className="whitespace-nowrap" key={index}>
                    <span className='text-xs mr-2'>{label}:</span>
                    <span className='text-sm font-bold'>{feedJSON.body.format?.[key] ? feedJSON.body.format[key](value) : value + ' ' + (feedJSON.body.value_append?.[key] ?? '')}</span>
                  </p>
                );
              })}
            </div>
          );
        }
        return chunks;
      };
    return (
        <li key={item.sk} className="relative flex justify-between gap-x-6 px-4 py-5 hover:bg-gray-50 sm:px-6 hover:cursor-pointer" onClick={() => {handleClick && handleClick({url:'/home/loan_detail/'+ item?.ATTR1?.id})}}>
                    <div className="min-w-0">
                        {<div className="flex items-start gap-x-3 mb-3" key="1">
                            <p className="text-sm font-semibold leading-6 text-gray-900">ID: {item?.ATTR1?.id}</p>
                            <p
                            className={classNames(
                                statuses[item?.ATTR1?.status??'applying'],
                                'mt-0.5 whitespace-nowrap rounded-md px-1.5 py-0.5 text-xs font-medium ring-1 ring-inset',
                            )}
                            >
                            {camelCaseToTitle(item?.ATTR1?.status??'applying')}
                            </p>
                            <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500" key="4">
                            <p className="whitespace-nowrap italic">
                                Last Edited {readableDate(item.modified_datetime)}
                            </p>
                            <svg viewBox="0 0 2 2" className="h-0.5 w-0.5 fill-current">
                                <circle r={1} cx={1} cy={1} />
                            </svg>
                            <p className="truncate italic">Modified by {item?.ATTR1?.username}</p>
                            </div>
                        </div>}
                        <div className="mb-2">
                            {renderChunks(item)}
                        </div>
                    </div>
                </li>
    )  
}
function ListLoanScreen() {
    const listJSON = {
        'body':{
            'title':'Loan Applications',
            'subtitle':'All loan applications.',
            'format':{'performa_invoice_date':readableDate, 'target_date':readableDate },
            'value_append':{ 'funding_expected': 'Rs.', 'performa_invoice_amount': 'Rs.',
                             'expected_loan_tenure_label':'months'
            },
            'action':{},
            'exclude':['eid', 'supplier','id', 'expected_loan_tenure', 'activity', 'loan_action', 'status'],
            'template':'renderFeedList' 
        },
        
        'fetch':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/list', 'method':'LoanAppId#'}
    }
    return BuildList({listJSON: listJSON, FeedUI: LoanFeedComponent})    
}
function ApplyLoanScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Trade Finance Loan Application', subtitle: 'Please upload your invoice and loan requirement details'},  
            { type: 'FTDynamicSelect', required: true, label: 'Supplier', field: 'supplier', fetch: {'endpoint':  API_CONFIG.API_BASE_URL + 'retailer/listCommon', 'method':'EnterpriseType#supplier', 'key': 'business_name'}},
            { type: 'FTTextInput', required: true, field: 'performa_invoice_no', label: 'Performa Invoice No' },
            { type: 'FTTextInput', required: true, inputType: 'date', field: 'performa_invoice_date', label: 'Performa Invoice Date'},
            { type: 'FTFileComponent', field:'invoice_file', placeholder: 'Upload your Performa Invoice'},
            
            { type: 'FTTextInput', required: true, field: 'performa_invoice_amount', label: 'Performa Invoice Amount(In Rs.)', placeholder: 'eg. 12345.56', error:['Please enter valid invoice amount'], validator:['isFloat']},
            { type: 'FTTextInput', required: true, field: 'funding_expected', label: 'Funding Expected Amount( In Rs.)', placeholder: 'eg. 4', error:['Please enter valid funding amount'], validator:['isNumeric']},
            { type: 'FTSelect', label: 'Expected Loan Tenure( In Months)', field: 'expected_loan_tenure', 
                options:[{l:'1', k:'1'},{l:'2', k:'2'},{l:'3', k:'3'},{l:'4', k:'4'},{l:'5', k:'5'}]
            },
            { type: 'FTTextInput', required: true, inputType: 'date', field: 'target_date', label: 'Target Date for securing Loan'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method':'LoanAppId#{{aid}}', 'navigate': '/home/list_loan'}
    }
    return CreateForm({'formJSON': form, ...props})
}
function FinancialDocumentScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Upload Financial Documents', subtitle: 'Please upload your bank transaction statement and other financial documents'},  
            { type: 'FTFileComponent', field:'bank_statements', placeholder: 'Upload your Bank Statement for last three years'},
            { type: 'FTFileComponent', field:'IT_document', placeholder: 'Upload your IT return document for last three years'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method':`FinancialDocuments#LoanAppId:${props.loanId}`, 'getpoint': API_CONFIG.API_BASE_URL + 'retailer/get'}
    }
    return CreateForm({'formJSON': form, ...props})
}
function PerformaInvoiceVerificationRequestScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Request Supplier for Performa Verification', subtitle: 'Click on the button below to request the supplier to verify the Performa Invoice'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/update', 'method':`LoanAppId#${props.loanId}`, 'data':{'status':'supplier_review'}}
    }
    return CreateForm({'formJSON': form, ...props})
}
function LoanDetailScreen({loanId}) {
    const {token, loading, logout} = useContext(AuthContext);
    const [loanApplication, setLoanApplication] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const params = useParams()
    const navigate = useNavigate()
    if(!loanId){
     loanId = params.loanId
    }
    useEffect(() => {
        const fetchLoanApplication = async () => {
          let res = await sendPostRequest({'url': API_CONFIG.API_BASE_URL + 'retailer/get', 'payload': { 'method': `LoanAppId#${loanId}`, 'meta':{token: token} }, 'setLoading':setIsLoading, 'handleLogout':logout } ) 
          console.log(res.data)
          if(res.data && res.data){
            setLoanApplication(res.data)
            console.log(res.data)
          }
        }
        try{
          if(!loading)
            fetchLoanApplication()  
        }catch(e){
          console.error(e)
        }
      }, [])
    
    
    return (
     isLoading?<LoadingOverlay/>:
        <>
            <div className='my-4 mb-6 border-b border-gray-100'>
                <LoanFeedComponent item={loanApplication} ></LoanFeedComponent>
            </div>
            {<div className="grid grid-cols-2 gap-20">
                <div className="col-span-1">
                    <StatusUpdate loanApplication={loanApplication}></StatusUpdate>
                </div>   
                <div className="col-span-1">
                    <ActionComponent loanApplication={loanApplication}></ActionComponent>  
                </div>    
            </div>}
        </> 
    )
}
function StatusUpdate({loanApplication}) {
    return (
      <>
        <ul role="list" className="space-y-6">
          {loanApplication.ATTR1 && loanApplication.ATTR1.activity && loanApplication.ATTR1.activity.length > 0 && loanApplication.ATTR1.activity.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id} className="relative flex gap-x-4">
              <div
                className={classNames(
                  activityItemIdx === loanApplication.ATTR1.activity.length - 1 ? 'h-6' : '-bottom-6',
                  'absolute left-0 top-0 flex w-6 justify-center',
                )}
              >
                <div className="w-px bg-gray-200" />
              </div>
              <>
                  <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                    <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                  </div>
                  <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500">
                    <span className="font-medium text-gray-900">{activityItem.name}</span> {activityItem.type}
                  </p>
                  <div className="flex-none py-0.5 text-xs leading-5 text-gray-500">
                    {timeAgo(activityItem.dateTime)}
                  </div>
                </>
            </li>
          ))}
        </ul>
      </>
    )
}
function ActionComponent({loanApplication}){
  const [open, setOpen] = useState(false)
  const [mesg, setMesg] = useState({content:'', title:''})
  const {token} = useContext(AuthContext);
    
  const navigate = useNavigate()
  const action = {
    'review':{
      'before': { title: 'Submit your application for Review',
                  content: 'Are you sure you want to submit the application for review?',
                  button: 'Submit'
                },
       'after': { title: 'Application Submitted',
                  content: 'Your application has been submitted for review.',
                  button:''
                }           
    },  
    'application':{
      'before': { title: 'Application In Progress',
                  content: 'Continue with your application',
                  button: 'Continue'
                },
       'after': { title: 'Application completed',
                  content: 'Your application has been completed',
                  button:''
                }           
    },  
  }
  const [application, setApplication] = useState((!loanApplication?.ATTR1?.status || loanApplication?.ATTR1?.status === 'applying')?action['application']['before']:action['application']['after'])
  const [review, setReview] = useState((!loanApplication?.ATTR1?.status || loanApplication?.ATTR1?.status === 'applying')?action['review']['before']:action['review']['after'])
  
  function handleReview(title, content, action ){
    setMesg({content:content, title:title, action:action})
    setOpen(true)
  }
  async function sendApplicationForReview(){ 
    console.log("Sending application for review")
    const resp = await sendPostRequest(API_CONFIG.API_BASE_URL + 'applicant/submitLoanApplication', { 'loanId': loanApplication.ATTR1.loan_id, 'token': token } )
    if(resp.status === 'success'){  
      setApplication(action['application']['after'])
      setReview(action['review']['after'])
    }
  }
  return (
    <div>
    {(open) && <Modal title={mesg.title} content={mesg.content} action={mesg.action} closeModal={() => setOpen(false)}></Modal>}
      <ul role="list" className="divide-y divide-gray-100">
          <li key="1" className="flex items-center justify-between gap-x-6 py-5">
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">{application.title}</p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{application.content}</p>
              </div>
            </div>
              <a
                href={"/home/loan_wf/" + loanApplication?.ATTR1?.id}
                className={(application.button === '')?'hidden':"rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" }
              >
              {application.button}
            </a>
          </li>
          <li key="2" className="flex items-center justify-between gap-x-6 py-5">
            <div className="flex min-w-0 gap-x-4">
              <div className="min-w-0 flex-auto">
                <p className="text-sm font-semibold leading-6 text-gray-900">{review.title}</p>
                <p className="mt-1 truncate text-xs leading-5 text-gray-500">{review.content}</p>
              </div>
            </div>
              <a
                onClick={() => handleReview('Submit for Review', 'Are you sure you want to submit the application for review?', sendApplicationForReview)} 
                className={(review.button === '')?'hidden':"rounded-full bg-white px-2.5 py-1 text-xs font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" }
              >
              {review.button}
            </a>
          </li>
      </ul>
    </div>
  )
}
function LoanWorkFlow({ loanId }) {
    const { setPageGroupId, token, logout, loading, setLoanAction, loanAction } = useContext(AuthContext);
    const [isLoading, setIsLoading] = useState(false);
    const [currentAction, setCurrentAction] = useState('');
    const [loanApplication, setLoanApplication] = useState({});
    const params = useParams();

    if (!loanId) {
        loanId = params.loanId;
    }

    useEffect(() => {
        const fetchLoanApplication = async () => {
            let res = await sendPostRequest({
                'url': API_CONFIG.API_BASE_URL + 'retailer/get',
                'payload': { 'method': `LoanAppId#${loanId}`, 'meta': { token: token } },
                'setLoading': setIsLoading,
                'handleLogout': logout
            });
            console.log('fetchLoanApplication', res.data);
            if (res?.data?.ATTR1?.loan_action) {
                setLoanApplication(res.data);
                setLoanAction(res.data.ATTR1.loan_action);
                const currentStep = res.data.ATTR1.loan_action.find(step => step.status === 'current');
                console.log('currentStep', currentStep);
                if (currentStep && token.pageMap[currentStep.action]) {
                    setCurrentAction(currentStep.action);
                }

            }
        };
        try {
            if (!loading && !loanAction)
                fetchLoanApplication();
        } catch (e) {
            console.error(e);
        }
        setPageGroupId(null);
    }, [loanId, loading, logout, setPageGroupId, token, setLoanAction]);

    useEffect(() => {
        //console.log('loanAction', loanAction);
        const updateLoanApplication = async () => {
            if (loanAction && loanAction.length > 0) {
                const currentStep = loanAction.find(step => step.status === 'current');
                if (currentStep && token.pageMap[currentStep.action]) {
                    setCurrentAction(currentStep.action);
                    //params['meta'] = { 'token': token } 
                    let params = { 'method': `LoanAppId#${loanId}`, 
                                   'data' : { 'loan_action': loanAction }, 
                                   'meta': { token: token }
                                }
                    let res = await sendPostRequest({'url': API_CONFIG.API_BASE_URL + 'retailer/update', 'payload': params })
                }
            }
        }
        updateLoanApplication()
        
    }, [loanAction]);
    function renderAction() {
        const element = token?.pageMap[currentAction]?.element;
        if(element)
            return React.createElement(componentMapping[element], {'loanId': loanId, 'workflow': true});
        return null;
    }

    function changeCurrentAction(action){
        //Also loop through loanAction and set the current action
        console.log('changeCurrentAction', action, currentAction);
        const newLoanAction = loanAction.map(step => {
            if(step.action === currentAction){ //Change the current action to inprogress
                return {...step, status: 'pending'}
            }
            if(step.action === action){ //Change the current action to inprogress
                return {...step, status: 'current'}
            }
            return step
        });
        console.log('newLoanAction', newLoanAction);
        setLoanAction(newLoanAction);
    }
    return (
        <>
            <div className='mb-2 border-b border-gray-100'>
                <LoanFeedComponent item={loanApplication} ></LoanFeedComponent>
            </div>
            <div className="flex px-4 py-6 w-full">
                <div className="flex-[2_2_0%]">
                    <nav aria-label="Progress" className="flex">
                        <ol role="list" className="space-y-6">
                            {loanAction && loanAction.length > 0 && loanAction.map((step, index) => {
                                if (!token.pageMap || !token.pageMap[step.action] || !token.pageMap[step.action].name)
                                    return null;
                                return (
                                    <li key={index}>
                                        {step?.status === 'complete' ? (
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                changeCurrentAction(step.action)
                                                }} className="group cursor-pointer">
                                                <span className="flex items-start">
                                                    <span className="relative flex size-5 shrink-0 items-center justify-center">
                                                        <CheckCircleIcon
                                                            aria-hidden="true"
                                                            className="size-full text-indigo-600 group-hover:text-indigo-800"
                                                        />
                                                    </span>
                                                    <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                                        {token.pageMap[step.action].name}
                                                    </span>
                                                </span>
                                            </a>
                                        ) : step?.status === 'current' ? (
                                            <a href="#" aria-current="step" className="flex items-start">
                                                <span aria-hidden="true" className="relative flex size-5 shrink-0 items-center justify-center">
                                                    <span className="absolute size-4 rounded-full bg-indigo-200" />
                                                    <span className="relative block size-2 rounded-full bg-indigo-600" />
                                                </span>
                                                <span className="ml-3 text-sm font-medium text-indigo-600">
                                                    {token.pageMap[step.action].name}
                                                </span>
                                            </a>
                                        ) : (
                                            <a onClick={(e) => {
                                                e.preventDefault();
                                                changeCurrentAction(step.action)
                                            }} className="group cursor-pointer">
                                                <div className="flex items-start">
                                                    <div aria-hidden="true" className="relative flex size-5 shrink-0 items-center justify-center">
                                                        <div className="size-2 rounded-full bg-gray-300 group-hover:bg-gray-400" />
                                                    </div>
                                                    <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                                        {token.pageMap[step.action].name}
                                                    </p>
                                                </div>
                                            </a>
                                        )}
                                    </li>
                                );
                            })}
                        </ol>
                    </nav>
                </div>
                <div className="flex-[8_8_0%]">
                    {renderAction()}
                </div>
            </div>``
        </>
    );
}
 
export { ListLoanScreen, ApplyLoanScreen, LoanDetailScreen, LoanWorkFlow, PerformaInvoiceVerificationRequestScreen, FinancialDocumentScreen }