import { CreateForm } from '../../component/Form';
import { API_CONFIG } from '../../helper/HttpHelper'

function RetailerBusinessDetailScreen(props){
    console.log('RetailerBusinessDetailScreen - props - ' + JSON.stringify(props))
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Business Detail', subtitle: ''},
            { type: 'FTTextInput', required: true, field: 'business_name', label: 'Name of the Input shop/Retailer/Business unit'},
            { type: 'FTSelect', label: 'Type of Registration', field: 'registration_type', options:[{l:'Private Ltd Company', k:'private_ltd'},
                {l:'Partnership', k:'partnership'},{l:'Proprietorship', k:'proprietorship'},
                {l:'Others', k:'others'}
             ]
            },
            { type: 'FTTextInput', required: true, field: 'year_of_incorporation', label: 'Year of starting the business'},
            { type: 'FTTextInput', required: true, field: 'business_owner_name', label: 'Name of the Business owner'},
            { type: 'FTSelect', label: 'Key Products', field: 'key_products', options:[{l:'Feed', k:'feed'},
                {l:'Fertilizer', k:'fertilizer'},{l:'Growth & Nutrition', k:'growth_nutrition'},{l:'Pest & Disease', k:'pest_disease'},
                {l:'Equipments', k:'equipments'},{l:'Chemicals', k:'chemicals'},{l:'Irrigation', k:'irrigation'},{l:'Others', k:'others'}
             ]
            },
            { type: 'FTSelect', label: 'Key Sectors', field: 'key_sectors', options:[{l:'Agriculture', k:'agriculture'},
                {l:'Aquaculture', k:'aquaculture'},{l:'Poultry', k:'poultry'},{l:'Dairy', k:'dairy'},{l:'Others', k:'others'} 
             ]
            },
            { type: 'FTTextAreaComponent', field:'shop_address', label: 'Shop Address', placeholder: 'Enter your shop address'},
            { type: 'FTTextAreaComponent', field:'warehouse_address', label: 'Warehouse Address', placeholder: 'Enter your warehouse address'},
            { type: 'FTTextInput', field: 'contact_number', label: 'Contact Number', placeholder: 'Enter your contact number'},
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method':'RetailerBusinessDetail', 'getpoint': API_CONFIG.API_BASE_URL + 'retailer/get'}
    }
    return CreateForm({'formJSON': form, ...props})
}
function RetailerGstAccessScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'GST Access Settings', subtitle: 'This information will be required to fetch your GST data. '},
            { type: 'FTTextInput', required: true, field: 'gst', label: 'Business GST Number', placeholder:'eg. 12AAACI1681G1Z0', error:['Please enter a valid GST Number'], validator:['isValidGSTNumber'] },
            { type: 'FTTextInput', required: true, field: 'gst_username', label: 'GST User Name', placeholder:'Username which you use to login to your GST portal' },
            { type: 'FTTextInput', required: true, field: 'gst_password', label: 'GST Password', placeholder:'Password to login to your GST portal' },
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method': 'RetailerGstAccessScreen', 'getpoint': API_CONFIG.API_BASE_URL + 'retailer/get'}
    }
    return CreateForm({'formJSON': form, ...props})
}
function RetailerITAccessScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Income tax Access Settings', subtitle: 'This information will be required to fetch your financial data. '},
            { type: 'FTTextInput', required: true, field: 'pan', label: 'Business PAN Number', placeholder:'eg. AIQPM3911D', error:['Please enter a valid PAN Number'], validator:['isValidPAN'] },
            { type: 'FTTextInput', required: true, field: 'it_username', label: 'Income Tax User Name', placeholder:'Username which you use to login to your income tax portal' },
            { type: 'FTTextInput', required: true, field: 'it_password', label: 'Income Tax Password', placeholder:'Password to login to your income tax portal' },
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method': 'RetailerITAccessScreen', 'getpoint': API_CONFIG.API_BASE_URL + 'retailer/get'}
    }
    return CreateForm({'formJSON': form, ...props})
}
function RetailerBankDetailScreen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Bank Account Details', subtitle: 'Please enter the details'},
            { type: 'FTTextInput', required: true, field: 'account_name', label: 'Account Name', error:['Please enter valid account name'], validator:['isNotEmpty'] },
            { type: 'FTTextInput', required: true, field: 'account_number', label: 'Account Number', error:['Please enter valid account number'], validator:['isNotEmpty'] },
            { type: 'FTSelect', label: 'Account Type', field: 'account_type', options:[{l:'Savings', k:'savings'},
                {l:'Current', k:'current'}]
            },
            { type: 'FTTextInput', required: true, field: 'ifsc_code', label: 'IFSC Code', error:['Please enter valid IFSC Code'], validator:['isNotEmpty'] },
            { type: 'FTTextInput', field:'bank_name', label: 'Bank Name', placeholder: 'eg. ICICI Bank'},
            { type: 'FTTextInput', field: 'authorised_signatory', label: 'Authorized Signatory for Banking transaction' },
            { type: 'FTTextInput', field: 'designation', label: 'Designation', placeholder: 'eg. Manager' },
            { type: 'FTTextInput', field: 'email', label: 'Email' },
            { type: 'FTTextInput', field: 'phone', label: 'Phone' },
            { type: 'FTTextInput', field: 'authorised_signatory1', label: 'Other Authorized Signatory for Banking transaction' },
            { type: 'FTTextInput', field: 'designation1', label: 'Designation', placeholder: 'eg. Manager' },
            { type: 'FTTextInput', field: 'email1', label: 'Email' },
            { type: 'FTTextInput', field: 'phone1', label: 'Phone' },
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method':'RetailerBankDetail', 'getpoint': API_CONFIG.API_BASE_URL + 'retailer/get'}
    }
    return CreateForm({'formJSON': form, ...props})
}
function RetailerBankDetail1Screen(props){
    const form = {
        'body': [
            { type: 'FTTitle',  field:'form_title', title: 'Other Bank Account Details', subtitle: 'Please enter the details'},
            { type: 'FTTextInput', required: true, field: 'account_name', label: 'Account Name', error:['Please enter valid account name'], validator:['isNotEmpty'] },
            { type: 'FTTextInput', required: true, field: 'account_number', label: 'Account Number', error:['Please enter valid account number'], validator:['isNotEmpty'] },
            { type: 'FTSelect', label: 'Account Type', field: 'account_type', options:[{l:'Savings', k:'savings'},
                {l:'Current', k:'current'}]
            },
            { type: 'FTTextInput', required: true, field: 'ifsc_code', label: 'IFSC Code', error:['Please enter valid IFSC Code'], validator:['isNotEmpty'] },
            { type: 'FTTextInput', field:'bank_name', label: 'Bank Name', placeholder: 'eg. ICICI Bank'},
            { type: 'FTTextInput', field: 'authorised_signatory', label: 'Authorized Signatory for Banking transaction' },
            { type: 'FTTextInput', field: 'designation', label: 'Designation', placeholder: 'eg. Manager' },
            { type: 'FTTextInput', field: 'email', label: 'Email' },
            { type: 'FTTextInput', field: 'phone', label: 'Phone' },
            { type: 'FTTextInput', field: 'authorised_signatory1', label: 'Other Authorized Signatory for Banking transaction' },
            { type: 'FTTextInput', field: 'designation1', label: 'Designation', placeholder: 'eg. Manager' },
            { type: 'FTTextInput', field: 'email1', label: 'Email' },
            { type: 'FTTextInput', field: 'phone1', label: 'Phone' },
            { type: 'FTButton', title: 'Submit', field: 'submit_button'}
        ],
        'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method':'RetailerBankDetail1', 'getpoint': API_CONFIG.API_BASE_URL + 'retailer/get'}
    }
    return CreateForm({'formJSON': form, ...props})
}


export { RetailerBusinessDetailScreen, RetailerBankDetailScreen, RetailerBankDetail1Screen, RetailerGstAccessScreen, RetailerITAccessScreen };