import { BrowserRouter, Routes, Route} from 'react-router-dom';
import React, { useState, useContext} from 'react';
import { SideBar } from './component/SideBar';
import { AuthContextProvider, AuthContext } from "./store/auth-context";
import { HeroScreen, ExampleScreen } from './screen/prelogin/HeroScreen';
import { SignUpScreen, EmailLoginScreen, VerifyAccountScreen, ForgotPasswordScreen, ResetPasswordScreen, ResendVerificationCodeScreen } from './screen/prelogin/SignUpScreen';
import { ListLoanScreen, ApplyLoanScreen, LoanDetailScreen, LoanWorkFlow } from './screen/retailer/Loan'
import { componentMapping } from './component/Component';
function App() {
  return (
    <>
      <AuthContextProvider>
        <ErrorBoundary>
          <Navigation />
        </ErrorBoundary>
      </AuthContextProvider>
    </>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h2>Something went wrong.</h2>
          <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }

    return this.props.children; 
  }
}

function Navigation() {
  const {token, loading, isAuthenticated} = useContext(AuthContext);
  const authCtx = useContext(AuthContext);
  return (
        <div className="h-full bg-white">
         <BrowserRouter>
          <Routes>
          {isAuthenticated && (
            <Route path="/home/*" element={<SideBar />}>
            {token?.menu?.length > 0 && token?.menu?.map((page) =>
              page.pages.map((route, index) => {
                if (!route.detail.role || (route.detail.role === 'admin' && token.role === 'admin') || (route.detail.role !== 'admin')) {
                  console.log('Printing Route detail ', route)
                  return (
                    <Route
                      key={index}
                      path={route.detail.path}
                      element={React.createElement(componentMapping[route.detail.element])}
                    />
                  );
                }
                return null;
              })
            )}
            </Route>
            )}
            <Route path="/" element={<HeroScreen />} />
            <Route path="/login/" element={<EmailLoginScreen />} />
            <Route path="/signup/:enterprise_type" element={<SignUpScreen />} />
            <Route path="/forgotPassword" element={<ForgotPasswordScreen />} />
            <Route path="/resetPassword" element={<ResetPasswordScreen />} />
            <Route path="/verifyAccount" element={<VerifyAccountScreen />} />
            <Route path="/resendVerificationCode" element={<ResendVerificationCodeScreen />} />
            
          </Routes>
        </BrowserRouter>
      </div>     
  );
}

export default App;
