
'use client'
import { useState, useContext, useEffect } from 'react';
import { camelCaseToTitle, extractFromJSON, getLastSixQuarters, getLastFourFinancialYears } from '../../component/Helper';
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { LoadingOverlay } from "../../component/Common";
import { sendPostRequest, API_CONFIG } from '../../helper/HttpHelper'
import { AuthContext } from '../../store/auth-context';
import { useNavigate, useLocation } from 'react-router-dom';
import { CreateForm } from '../../component/Form';


function BasicInfoScreen() {
    const obj = {
        "requestId": "c3f535a4-0d57-4a07-b515-37be1375dedd",
        "basicDetails": {
            "gstin": "21AABAP1914G1Z5",
            "ekycFlag": "No",
            "compositionRate": "NA",
            "percentTaxInCash": "NA",
            "aggreTurnOverFY": "2023-2024",
            "registrationType": "Regular",
            "aggreTurnOver": "Slab: Rs. 1.5 Cr. to 5 Cr.",
            "businessNature": [
                "Retail Business",
                "Office / Sale Office"
            ],
            "registrationDate": "01/07/2017",
            "registrationStatus": "Active",
            "percentTaxInCashFY": "",
            "memberDetails": [
                "HARI PARAJA ",
                "JIBADAS  PANGI ",
                "TIL  HARIJAN ",
                "HARIBANDHU  BHATARA ",
                "RAGHUNATH  HALABA ",
                "SANDARU  BHUMIA ",
                "SANGHATRA  HARIJANI ",
                "DIBAKAR  NAIK ",
                "KAMAL  GOUD ",
                "DHAN  PUJARI "
            ],
            "natureOfCoreBusinessActivity": "TRD:TRR",
            "aadhaarVerified": "No",
            "legalBusinessName": "PATNESWARI AGRI. COOPERATIVE SOCIETY LIMITED",
            "constitutionOfBusiness": "Society/ Club/ Trust/ AOP",
            "tradeName": "M/S. PATNESWARI AGRI. COOPERATIVE SOCIETY LIMITED",
            "centralJurisdiction": "State - CBIC,Zone - BHUBANESWAR,Commissionerate - BHUBANESWAR,Division - RAYAGADA DIVISION,Range - KORAPUT RANGE (Jurisdictional Office)",
            "isEInvoiceMandated": "No",
            "stateJurisdiction": "State - Odisha,Range - Koraput,Circle - Koraput Circle",
            "cancellationDate": "",
            "isEInvoiceOpted": "Yes"
        },
        "mobile": "9437236826",
        "email": "pacol0354@gmail.com",
        "hsnDetails": {
            "goods": [
                {
                    "hsnCode": "47050000",
                    "hsnDescription": "WOOD PULP OBTAINED BY A COMBINATION OF MECHANICAL AND CHEMICAL PULPING PROCESSES"
                },
                {
                    "hsnCode": "85437092",
                    "hsnDescription": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- OTHER ---- EQUIPMENT GADGETS BASED ON SOLAR ENERGY"
                },
                {
                    "hsnCode": "31031000",
                    "hsnDescription": "MINERAL OR CHEMICAL FERTILISERS, PHOSPHATIC SUPERPHOSPHATES"
                },
                {
                    "hsnCode": "39172950",
                    "hsnDescription": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF OTHER PLASTICS :TUBES OF VINYL PLASTICS"
                },
                {
                    "hsnCode": "12071090",
                    "hsnDescription": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN PALM NUTS AND KERNELS : OTHER"
                }
            ],
            "services": null
        },
        "branchDetails": {
            "message": "",
            "principalAddress": {
                "address": "HARSHA TRUST BUILDING, PARABEDA, JEYPORE, Koraput, Odisha, 764001",
                "natureOfBusiness": "Retail Business, Office / Sale Office"
            },
            "additionalAddresses": []
        },
        "filingDetails": {
            "FilingStatus": [
                [
                    {
                        "FinYear": "2024-2025",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "July",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "20/08/2024"
                    },
                    {
                        "FinYear": "2024-2025",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "June",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "20/07/2024"
                    },
                    {
                        "FinYear": "2024-2025",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "April",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "20/05/2024"
                    },
                    {
                        "FinYear": "2024-2025",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "May",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "14/06/2024"
                    },
                    {
                        "FinYear": "2024-2025",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "May",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "11/06/2024"
                    },
                    {
                        "FinYear": "2024-2025",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "July",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "10/08/2024"
                    },
                    {
                        "FinYear": "2024-2025",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "June",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "10/07/2024"
                    },
                    {
                        "FinYear": "2024-2025",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "April",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "10/05/2024"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "August",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "21/09/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "November",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "20/12/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "September",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "20/10/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "July",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "20/08/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "March",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "20/04/2024"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "January",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "20/02/2024"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "December",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "20/01/2024"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "May",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "19/06/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "April",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "19/05/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "February",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "19/03/2024"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "October",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "18/11/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR3B",
                        "ReturnPeriod": "June",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "17/07/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "November",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "11/12/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "September",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "11/10/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "August",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "11/09/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "June",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "11/07/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "April",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "11/05/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "December",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "11/01/2024"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "October",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "10/11/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "July",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "10/08/2023"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "March",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "10/04/2024"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "February",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "10/03/2024"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "January",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "10/02/2024"
                    },
                    {
                        "FinYear": "2023-2024",
                        "ReturnType": "GSTR1",
                        "ReturnPeriod": "May",
                        "ModeOfFiling": "ONLINE",
                        "DateOfFiling": "09/06/2023"
                    }
                ]
            ]
        },
        "liabilityPaidDetails": {
            "currFinYear": "2024",
            "prevFinYear": "2023",
            "prevTotalPct": "100",
            "currDetails": [
                {
                    "period": "Apr-24",
                    "liabPaidPct": 100
                },
                {
                    "period": "May-24",
                    "liabPaidPct": 100
                },
                {
                    "period": "Jun-24",
                    "liabPaidPct": 100
                },
                {
                    "period": "Jul-24",
                    "liabPaidPct": 100
                }
            ],
            "prevDetails": [
                {
                    "period": "Apr-23",
                    "liabPaidPct": 100
                },
                {
                    "period": "May-23",
                    "liabPaidPct": 100
                },
                {
                    "period": "Jun-23",
                    "liabPaidPct": 101
                },
                {
                    "period": "Jul-23",
                    "liabPaidPct": 101
                },
                {
                    "period": "Aug-23",
                    "liabPaidPct": 100
                },
                {
                    "period": "Sep-23",
                    "liabPaidPct": 100
                },
                {
                    "period": "Oct-23",
                    "liabPaidPct": 100
                },
                {
                    "period": "Nov-23",
                    "liabPaidPct": 102
                },
                {
                    "period": "Dec-23",
                    "liabPaidPct": 103
                },
                {
                    "period": "Jan-24",
                    "liabPaidPct": 0
                },
                {
                    "period": "Feb-24",
                    "liabPaidPct": 101
                },
                {
                    "period": "Mar-24",
                    "liabPaidPct": 100
                }
            ]
        },
        "turnOver": null
    }
    const paths = [
      '$.basicDetails.*',
      '$.mobile',
      '$.email',
      '$.hsnDetails.goods..hsnDescription',
      '$.branchDetails.principalAddress[*]'
    ]
    const exclude = ['compositionRate', 'aadhaarVerified', 'isEInvoiceMandated', 'cancellationDate', 'isEInvoiceOpted']
    const profile = extractFromJSON(obj, paths, exclude)

    console.log(profile)  
    //return
    return (
        <div className="flex h-full">
            <dl className="grid grid-cols-5 gap-x-3 gap-y-8">
                {Object.keys(profile).map((field) => (
                <div key={field}>
                    <dt className="text-sm font-medium text-gray-500">{camelCaseToTitle(field)}</dt>
                    <dd className="mt-1 text-sm text-gray-900">{profile[field]}</dd>
                </div>
                ))}
            </dl>
        </div>
    )
}
function AnnualInfoScreen(){
    const annuals = getLastFourFinancialYears();
    const params = ['Net Sales', 'Profit & Loss']

    const financials = params.map((param) => {
      const row = { name: param }
      annuals.forEach((annual) => {
        row[annual] = ''
      })    
      return row
    })
    console.log(financials)
    return <div className="inline-block min-w-full py-3 align-middle sm:px-6 lg:px-8">
          <h2 className="text-base font-semibold leading-10 text-gray-600">Annual Info</h2>
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr className="divide-x divide-gray-200">
                <th scope="col" className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                </th>
                {annuals.map((annual, index) => (
                <th scope="col" className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900">
                  {annual}
                </th>
                ))}
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {financials.map((row) => (
                    <tr key={row.name} className="divide-x divide-gray-200">
                      {Object.keys(row).map((key, index) => (
                      (index == 0)?(
                          <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                              {row.name}
                          </td> 
                      ):(
                          <td className="whitespace-nowrap p-4 text-sm text-gray-500"><div className="relative mt-2">
                              <input
                                name="name"
                                type="text"
                                placeholder=""
                                className="peer block w-full border-0 bg-gray-50 py-1.5 text-gray-900 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                              <div
                                aria-hidden="true"
                                className="absolute inset-x-0 bottom-0 border-t border-gray-300 peer-focus:border-t-2 peer-focus:border-indigo-600"
                              />
                            </div>          
                          </td>
                      )
                    ))}
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
}  
function QuarterlyInfoScreen(props){
  const form = {
    'body': [
        { type: 'FTTitle',  field:'form_title', title: 'Last six Quarterly results ', subtitle: ''},  
        { type: 'FTMultiTextInput', field:'quarterly_info', params: ['Net Sales', 'Net Purchase', 'Cash-in-Hand', 'Cash-in-Bank', 'Stock-in-Hand', 'Receivables', 'Payables'], 'header_fields':getLastSixQuarters},
        { type: 'FTButton', title: 'Submit', field: 'submit_button'}
    ],
    
    'submit':{ 'endpoint': API_CONFIG.API_BASE_URL + 'retailer/add', 'method':`QuarterlyInfo#LoanAppId:${props.loanId}`, 'getpoint': API_CONFIG.API_BASE_URL + 'retailer/get'}
  }
  return CreateForm({'formJSON': form, ...props})
}
export { BasicInfoScreen, AnnualInfoScreen, QuarterlyInfoScreen  }