import { ApproveEnterpriseScreen, ListRetailerScreen, ListSupplierScreen, ListFinancierScreen } from '../screen/admin/ManageEnterprise';
import { SupplierCompanyDetailScreen, SupplierBankDetailScreen, SupplierBankDetail1Screen } from '../screen/supplier/Profile';
import { ManageSupplierUserScreen, SupplierAuditLogScreen } from '../screen/supplier/Manage';
import { RetailerBusinessDetailScreen, RetailerBankDetailScreen, RetailerBankDetail1Screen,RetailerGstAccessScreen, RetailerITAccessScreen } from '../screen/retailer/Profile';
import { ListLoanScreen, ApplyLoanScreen, LoanDetailScreen, LoanWorkFlow, PerformaInvoiceVerificationRequestScreen, FinancialDocumentScreen } from '../screen/retailer/Loan'
import { FinancierCompanyDetailScreen, FinancierProduct1Screen, FinancierProduct2Screen } from '../screen/financier/Profile';
import { BasicInfoScreen, QuarterlyInfoScreen, AnnualInfoScreen } from '../screen/retailer/Financial';

const componentMapping = {
  FinancialDocumentScreen:FinancialDocumentScreen,
  PerformaInvoiceVerificationRequestScreen:PerformaInvoiceVerificationRequestScreen,
  RetailerGstAccessScreen: RetailerGstAccessScreen,
  RetailerITAccessScreen: RetailerITAccessScreen,
  LoanWorkFlow: LoanWorkFlow,
  LoanDetailScreen:LoanDetailScreen,
  AnnualInfoScreen:AnnualInfoScreen,
  QuarterlyInfoScreen:QuarterlyInfoScreen,
  BasicInfoScreen:BasicInfoScreen,
  SupplierCompanyDetailScreen:SupplierCompanyDetailScreen, 
  SupplierBankDetailScreen:SupplierBankDetailScreen,
  SupplierBankDetail1Screen:SupplierBankDetail1Screen,
  RetailerBusinessDetailScreen:RetailerBusinessDetailScreen,
  RetailerBankDetailScreen:RetailerBankDetailScreen,
  RetailerBankDetail1Screen:RetailerBankDetail1Screen,
  FinancierCompanyDetailScreen: FinancierCompanyDetailScreen,
  FinancierProduct1Screen: FinancierProduct1Screen,
  FinancierProduct2Screen: FinancierProduct2Screen,
  ListLoanScreen: ListLoanScreen,
  ApplyLoanScreen: ApplyLoanScreen,
  ApproveEnterpriseScreen: ApproveEnterpriseScreen,
  ListRetailerScreen: ListRetailerScreen,
  ListSupplierScreen: ListSupplierScreen,
  ManageSupplierUserScreen: ManageSupplierUserScreen,  
  ListFinancierScreen: ListFinancierScreen,
  SupplierAuditLogScreen: SupplierAuditLogScreen
};

export { componentMapping };